.Toastify__toast-container{
    width: 330px;
    right:0;
    left: auto;
}

.toastMessage {
    color: #FFF;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    font-family: 'Outfit', sans-serif;
}

@media (min-width: 1024px) {
    .Toastify__toast-container{
        width: 384px;
    }
}
